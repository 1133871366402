.deposit {
  display: grid;
  grid-template-columns: repeat(2, 378px);
  gap: 80px;
  padding: 40px;
  background-color: #fff;
  height: 100%;

  &__left {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h1 {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0.34px;
      color: var(--color-neutral--900);
    }

    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.34px;
      color: #687385;
    }

    &__switch {
      display: flex;
      gap: 4px;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.154px;
        color: var(--color-neutral--700);
        padding-left: 12px;
      }
    }

    &__action {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: #fff;
      background-color: var(--color-primary-1000);
      border-radius: 4px;
      box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.12) inset,
        0px 2px 5px 0px rgba(60, 66, 87, 0.12),
        0px 1px 1px 0px rgba(0, 0, 0, 0.08);
      border: none;
      padding: 6px;
    }

    &__details {
      h2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        color: #687385;
      }

      &__grid {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 20px;

        &--id {
          font-family: Menlo;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 11px;
          color: var(--color-neutral-600);
          border-radius: 4px;
          border: 1px solid var(--color-neutral-200);
          background: var(--color-neutral-100);
          padding: 3px 6px;
          width: max-content;
        }

        &__item {
          display: flex;
          flex-direction: column;
          gap: 8px;
          span {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            color: var(--color-neutral-600);
          }
          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-600);
          }

          &__action {
            padding-top: 30px;

            &--inactive {
              background-color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: -0.154px;
              padding: 4px 8px;
              border-radius: 4px;
              border: 1px solid var(--color-neutral-200);
            }
          }
        }
      }
    }
  }
  &__right {
    &__info {
      width: 360px;
      &__card {
        display: flex;
        flex-direction: column;
        padding: 12px;
        background-color: var(--color-neutral-100);
        border-radius: 6px;

        &--title {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          background-color: var(--color-neutral-200);
          border-radius: 4px;
          padding: 0 6px;
          color: var(--color-neutral-600);
          align-self: flex-start;
        }

        &--info {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.154px;
          color: var(--color-primary-1000);
          padding-top: 16px;
        }

        &--text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.154px;
          color: var(--color-neutral-700);
          padding-top: 8px;
        }
      }
    }
  }
}
