.pageInternalError {
  background: #000;
  height: 100vh;
  width: 100vw;

  &__container {
    max-width: 1366px;
    padding: 40px;
    margin: 0 auto;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    p {
      padding-top: 40px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .pageInternalError {
    &__container {
      padding: 20px;
    }
  }
}
