.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip img {
  padding-bottom: 5px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #fff;
  color: var(--color-neutral-700);
  border-radius: 6px;
  border-radius: 4px;

  position: absolute;
  z-index: 1;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.154px;
  box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.12),
    0px 5px 15px 0px rgba(0, 0, 0, 0.08);
  padding: 16px;
  bottom: 33px;
  left: -13px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -139px;
  border-width: 8px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
