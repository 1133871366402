.selectFilter {
  select {
    border: 1px solid var(--color-neutral-200);
    border-radius: 4px;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.154px;

    width: 156px;
    padding: 5px;
    height: 30px;
    background: url('../../assets/arrow-bottom-gray.png') no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 135px;

    color: var(--color-neutral-600);

    option {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.154px;
    }
  }
}
