.modal {
  display: grid;
  grid-template-rows: 1fr max-content;
  padding: 30px;
  border-radius: 20px;
  height: 100%;

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;

    button {
      cursor: pointer;
      background-color: #fff;
    }
    &--continue {
      border: 2px solid #000000;
      border-radius: 10px;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      padding: 16px 32px;
    }
    &--return {
      border: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
    }
  }
}
