@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
@import url('https://fonts.cdnfonts.com/css/menlo');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  line-height: 1.2;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  button {
    font-size: 15px;
  }

  input {
    height: 37.5px;
  }

  select {
    height: 37.5px;

    option {
      width: 200px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

@mixin checkout-box {
  padding: 36px 30px;
  background-color: black;
  border: 2px solid transparent;
  // border-image: linear-gradient(#605cff 0%, #7c4aff 18.75%, #f7892e 45.31%, #c3e3c4 77.6%) 1;
  // border-width: 2px;
  // border-style: solid;
  border-radius: 10px;
  display: flex;
  background-clip: padding-box;
  flex-direction: column;
  position: relative;
  width: 428px;

  &::after {
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background: linear-gradient(
      112.27deg,
      #605cff 0%,
      #7c4aff 18.75%,
      #f7892e 45.31%,
      #c3e3c4 77.6%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    z-index: -1;
    border-radius: 10px;
  }
}

@keyframes rotate-gradient {
  to {
    transform: rotate(360deg);
  }
}

@mixin light-outline-btn {
  outline: none;
  border: none;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  padding: 5px 25.5px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 10px;
  color: white;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #000;
  }
}
@mixin light-no-outline-btn {
  outline: none;
  border: none;
  padding: 5px 16px;
  background-color: transparent;
  border-color: transparent;
  color: white;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  font-family: 'DM Sans', sans-serif;
  transition: all 0.3s ease-in-out;
  border: none;
  display: block;
  position: relative;

  &:before {
    content: '';
    border-bottom: 2px solid white;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    margin: 0 auto;
    width: 0;
  }
  &:hover:before {
    width: 100%;
    padding: 0;
  }
}

@mixin dark-btn {
  outline: none;
  border: none;
  border: 1px solid #121212;
  background: #121212;
  border-radius: 10px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 6.5px 19px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    color: #000;
  }
}

@mixin dark-outline-btn {
  outline: none;
  border: none;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  padding: 12px 32px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 10px;
  color: #000;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: black;
    color: white;
  }
}

@mixin dark-no-outline-btn {
  outline: none;
  border: none;
  padding: 5px 16px;
  background-color: transparent;
  border-color: transparent;
  color: black;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  font-family: 'DM Sans', sans-serif;
  transition: all 0.3s ease-in-out;
  border: none;
  display: block;
  position: relative;

  &:before {
    content: '';
    border-bottom: 2px solid black;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    margin: 0 auto;
    width: 0;
  }
  &:hover:before {
    width: 100%;
    padding: 0;
  }
}

.MuiDataGrid-root {
  background: #ffffff !important;
  border: none !important;
  font-family: 'DM Sans', sans-serif !important;
  border-radius: 10px !important;
  .MuiDataGrid-columnHeaders {
    padding: 13px 12.5px !important;
  }
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #bec6cf;
  }
  .MuiDataGrid-row {
    .MuiDataGrid-cell {
      padding-left: 25px !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #121212;
    }
    .MuiDataGrid-cell:last-of-type {
      padding-right: 25px !important;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .MuiDataGrid-root {
    background: none !important;
    font-family: 'DM Sans', sans-serif !important;

    .MuiDataGrid-columnHeader {
      padding: 0px !important;
    }

    .MuiDataGrid-columnHeaders {
      padding: 0px !important;
      border-bottom: none;
    }
    .MuiDataGrid-columnSeparator {
      display: none !important;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #818181;
    }
    .MuiDataGrid-row {
      .MuiDataGrid-cell {
        padding-left: 0 !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #121212;
        border: none;
      }
      .MuiDataGrid-cell:last-of-type {
        padding-right: 0 !important;
      }
    }
  }
}
