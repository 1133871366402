.orderDetails {
  display: grid;

  &__divideHorizontal {
    width: 100%;
    border-bottom: 1px solid var(--color-neutral-200);
  }

  &__card {
    padding: 24px 40px 40px 40px;
    background-color: #fff;
    &__title {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      align-items: center;
      gap: 8px;
      padding-bottom: 8.5px;

      h1 {
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 11px;
        letter-spacing: 0.34px;
        text-transform: uppercase;
        color: var(--color-neutral-600);
      }

      span {
        font-family: Menlo;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 11px;
        color: var(--color-neutral-600);
      }
    }
    &__review {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      gap: 8px;
      align-items: center;
      padding-bottom: 28px;

      .targetAmount {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.34px;
        color: var(--color-neutral-900);
      }

      .targetAsset {
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        color: var(--color-neutral-600);
      }
    }
    &__resume {
      display: grid;
      grid-template-columns: repeat(9, max-content);
      gap: 23px;
      padding-bottom: 54px;

      &--divide {
        border-right: 1px solid var(--color-neutral-200);
      }

      &--grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
        align-items: center;
      }

      &--title {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-600);
      }
      &--text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-700);
      }
    }
    &__timeline {
      display: grid;
      gap: 12px;
      padding-bottom: 54px;

      h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.34px;
        color: var(--color-neutral-900);
      }

      &__item {
        display: grid;
        gap: 8px;

        &__title {
          display: grid;
          grid-template-columns: repeat(2, max-content);
          gap: 12px;
          align-items: center;

          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-900);
          }
        }

        &__date {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          padding-left: 24px;
          color: var(--color-neutral-700);
        }
      }
    }
    &__details {
      display: grid;
      gap: 12px;

      h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.34px;
        color: var(--color-neutral-900);
      }

      &__content {
        display: grid;
        gap: 12px;

        &__grid {
          display: grid;
          grid-template-columns: 162px max-content;
          gap: 12px;
          align-items: center;

          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-600);
          }
        }
      }
    }
  }
}
