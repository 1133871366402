.integrations {
  display: flex;
  flex-direction: column;
  gap: 28px;
  overflow-y: auto;

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-neutral-600);
  }

  &__line {
    border-bottom: 1px solid var(--color-neutral-200);
  }

  &__card {
    display: grid;
    background: #fff;
    border-radius: 10px;

    p {
      color: var(--color-neutral-900);
      padding-bottom: 20px;

      a {
        color: #000000;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-bottom: 20px;

      &__grid {
        display: inline-block;
        flex-direction: column;

        &--title {
          color: var(--color-neutral-900);
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
        }

        &--text {
          display: inline-block;
          background-color: var(--color-neutral-100);
          border-radius: 4px;
          border: 1px solid var(--color-neutral-200);
          margin-top: 6px;
          padding: 3px 6px;
          margin-right: 10px;
        }
      }
    }

    &__actions {
      display: flex;
      padding-top: 20px;
      button {
        background: var(--color-primary-900);
        border: none;
        color: #fff;
        padding: 12px 32px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 400;
        cursor: pointer;
      }
    }

    &__url {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 362px;
    }
  }
}
