.settlementDetails {
  overflow-y: auto;
  padding: 39px 45px;
  flex: 1;
  color: black;
  display: flex;
  flex-direction: column;
  &__header {
    color: #000000;
  }
  &__details {
    &-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      align-items: center;
      padding: 28px 51px 28px 28px;
      background: #ffffff;
      border-radius: 10px;
      margin-top: 20px;

      h3 {
        display: none;
      }
      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #8e8e8e;
        margin-top: 3px;
      }
      section {
        display: flex;
        flex-direction: column;

        p {
          color: #B5BFC9;
        }

        .status {
          margin-top: 10px;
          border-radius: 4px;
          height: 38px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #121212;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &-title {
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
    }
    &-amount {
      font-weight: 700;
      font-size: 35px;
      line-height: 46px;
      sup {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  &__table {
    &-container {
      display: flex;
      margin-top: 28px;
      flex-direction: column;
    }
    &-header {
      font-weight: 500;
      font-size: 20px;
      line-height: 33px;
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .settlementDetails {
    padding: 0 20px 20px 20px;

    &__header {
      display: none;
    }
    &__details {
      &-container {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 0;
        padding: 23px;
        gap: 20px;

        h3 {
          display: block;
          text-align: center;
          font-weight: 500;
          font-size: 30px;
          line-height: 105%;
          letter-spacing: -0.04em;
        }
      }
    }
    &__table {
      &-header {
        color: #000;
        text-align: center;
        font-size: 30px;
        line-height: 105%;
        letter-spacing: -0.04em;
      }
      &-container {
        padding: 23px;
        background: #f5f5f5;
        border-radius: 20px;
        margin-top: 25px;
      }
    }
  }
}
