.banner {
  border-radius: 4px;
  border: 1px solid #e0e6eb;
  padding: 16px;

  &__title {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    gap: 12px;
    align-items: center;

    h2 {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.154px;
      color: #30313d;
    }

    &__action {
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  p {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: #687385;
    padding-left: 28px;
  }

  &--neutral {
    background-color: none;
  }

  &--warning {
    background-color: #fef9da;
  }

  &--error {
    background-color: #fff5fa;
  }

  &--success {
    background-color: #d7f7c2;
  }
}
