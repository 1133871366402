.registerBank {
  display: grid;
  gap: 24px;

  &__button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
    border-radius: 4px;
    padding: 4px;
    border: none;
    background-color: var(--color-primary-1000);
    box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.12) inset,
      0px 2px 5px 0px rgba(60, 66, 87, 0.12),
      0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  }
}
