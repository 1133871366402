.settlementHistory {
  overflow-y: auto;
  padding: 39px 45px;
  flex: 1;
  color: black;
  display: flex;
  flex-direction: column;
  &__header {
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    color: #000000;
  }
  &__details {
    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 28px 51px 28px 28px;
      background: #ffffff;
      border-radius: 10px;
      margin-top: 20px;
      h3 {
        display: none;
      }
      section {
        display: flex;
        flex-direction: column;
        button {
          border: 1px solid #000000;
          border-radius: 10px;
          background: none;
          padding: 17px;
          cursor: pointer;
        }
      }
    }
    &-title {
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
    }
  }
  &__table {
    &-container {
      display: flex;
      margin-top: 28px;
      flex-direction: column;
      gap: 22px;
    }
    &-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 33px;
    }
    &-header {
      display: flex;
      align-items: center;
      gap: 26px;
      input {
        outline: none;
        border: none;
        font-family: 'DM Sans', sans-serif;
        font-weight: 400;
        font-size: 15px;
        margin-left: auto;
        line-height: 20px;
        padding: 3px 15px 3px 32px;
        background: #ffffff;
        border-radius: 10px;
        background-image: url('../../assets/search.svg');
        background-position: 8px 50%;
        background-repeat: no-repeat;
        color: black;
        &::placeholder {
          color: #818181;
        }
      }
    }
    &-dropdown {
      display: flex;
      align-items: center;
      select {
        border: none;
        background-color: #f5f5f5;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .settlementHistory {
    padding: 0 20px 20px 20px;

    &__header {
      display: none;
    }
    &__details {
      &-container {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 0;
        padding: 23px;

        h3 {
          display: block;
          text-align: center;
          font-weight: 500;
          font-size: 30px;
          line-height: 105%;
          letter-spacing: -0.04em;
          padding-bottom: 36px;
        }
        button {
          margin-top: 30px;
        }
      }
    }
    &__table {
      &-title {
        color: #000;
        text-align: center;
        font-size: 30px;
        line-height: 105%;
        letter-spacing: -0.04em;
        padding-bottom: 20px;
      }
      &-container {
        padding: 23px;
        background: #f5f5f5;
        border-radius: 20px;
        margin-top: 25px;
      }
      &-header {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;

        input {
          width: 100%;
        }
      }
      &-dropdown {
        select {
          border: none;
          background-color: #fff;
          width: 100%;
          height: 37px;
          border-radius: 6px;
        }
      }
    }
  }
}
