.progressLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-template-columns: auto;
  gap: 8px;

  &__ball {
    border-radius: 100%;
    height: 12px;
    width: 12px;
  }

  &__ball--blue {
    background-color: #0038ff;
  }
  &__ball--gray {
    border: 1px solid #b9b9c3;
  }

  &__line {
    background-color: #b9b9c3;
    width: 40px;
    height: 1px;
  }
}

@media only screen and (max-width: 1023px) {
  .progressLine {
    &__ball--blue {
      background-color: #fff;
    }
  }
}
