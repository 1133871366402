.formDocuments {
  h1 {
    font-size: 25px;
    font-weight: 400;
    line-height: 33px;
    padding-bottom: 29px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
  }

  &--separate {
    border: 1px dashed rgba(60, 60, 67, 0.3);
    margin: 24px 0;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__action {
    display: grid;
    grid-template-columns: max-content;
    gap: 86px;
    place-content: flex-end;
    padding: 30px 0 18px 0;

    button {
      line-height: 23px;
      font-weight: 400;
      background-color: #000;
      color: #fff;
      padding: 17px;
      border-radius: 10px;
      cursor: pointer;
    }

    button:disabled {
      cursor: no-drop;
      opacity: 0.8;
    }
  }
}

.uploadFile {
  display: grid;
  gap: 8px;
  text-align: left;
  border: none;
  background-color: #fff;
  cursor: pointer;
  width: 100%;

  &__file {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 10px;
    align-items: center;

    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
    }

    button {
      background-color: #fff;
      border: none;
      z-index: 99;
      cursor: pointer;
      background-image: url('../../assets/remove-file-black.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
    }
  }

  label {
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: rgba(60, 60, 67, 0.6);
  }

  p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: rgba(60, 60, 67, 0.6);
    padding: 0;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    padding: 20px;
  }

  &__icon {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    span {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      color: #0038ff;
    }
    &--svg {
      background-image: url('../../assets/cloud-upload-blue.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .formDocuments {
    h1 {
      text-align: center;
      padding-bottom: 10px;
    }
    p {
      text-align: center;
      font-weight: 500;
      font-size: 15px;
    }

    &--separate {
      border: 1px dashed #fff;
      margin: 24px 0;
    }

    &__action {
      display: flex;
      button {
        border: 1px solid #fff;
        width: 100%;
      }
    }
  }

  .uploadFile {
    background-color: #000;

    p {
      font-weight: 500;
      font-size: 15px;
      color: #fff;
      text-align: left;
    }

    label {
      color: #fff;
      font-size: 15px;
      font-weight: 400;
    }

    span {
      width: calc(100vw - 75px);
      font-weight: 500;
      font-size: 14px;
      color: #adadad;
      overflow-wrap: break-word;
    }

    &__file {
      button {
        background-image: url('../../assets/remove-file-gray.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
        background-color: #000;
      }
    }

    &__icon {
      span {
        color: #fff;
      }
      &--svg {
        background-image: url('../../assets/cloud-upload-white.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
      }
    }
  }
}
