.select {
  display: grid;
  grid-template-columns: 1fr;

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: var(--color-neutral-900);
  }

  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-neutral-900);
  }

  select {
    border: 1px solid #121212;
    padding: 6px 16px;
    border-radius: 6px;
    background: #fff !important;
    border: 1px solid var(--color-neutral-200);
    box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.12),
      0px 1px 1px 0px rgba(0, 0, 0, 0.08);
    margin-top: 4px;
    height: 30px;
  }

  select:disabled {
    opacity: 0.5;
  }

  &__error {
    display: flex;
    gap: 4px;
    align-items: center;
    padding-top: 8px;

    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      color: #cd3d64;
    }
  }

  .error {
    border: 1px solid #df1b41 !important;
  }
}
