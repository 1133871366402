.tagStatus {
  display: grid;
  max-width: 10px;

  &__grid {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    padding: 3px 6px;
    border-radius: 4px;
    height: 18px;

    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }
  }
}

.tagStatus--positive {
  background-color: var(--color-positive-light);

  p {
    color: var(--color-positive-dark);
  }
}

.tagStatus--negative {
  background-color: var(--color-error-light);

  p {
    color: var(--color-error-dark);
  }
}

.tagStatus--info {
  background-color: var(--color-info-light);

  p {
    color: var(--color-info-dark);
  }
}

.tagStatus--warning {
  background-color: var(--color-attention-light);

  p {
    color: var(--color-attention-dark);
  }
}

.tagStatus--neutral {
  background-color: var(--color-neutral-200);

  p {
    color: var(--color-neutral-600);
  }
}

.tagStatus--urgent {
  background-color: #df1b41;

  p {
    color: #fff;
  }
}
