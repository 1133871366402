@use '../../App.scss' as app;

.customerService {
  background-color: #ffffff;
  padding: 24px 40px;
  height: calc(100vh - 52px);

  &__header {
    padding-bottom: 30px;
    h1 {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 128.571% */
      letter-spacing: 0.34px;
      color: var(--color-neutral-900);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
  }

  &__message {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
    width: 372px;

    &__header {
      display: none;
    }

    label {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      gap: 21px;
      color: #000;

      textarea {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        border: 1px solid var(--color-neutral-400);
        border-radius: 4px;

        &::placeholder {
          color: #b5bfc9;
        }
      }
    }
    button {
      padding: 8px 79.5px;
      width: 100%;
      background-color: var(--color-primary-900);
      margin: 20px 0;
      border: none;
      color: #ffffff;
      border-radius: 4px;
    }
  }
  &__sent {
    height: 415px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    svg {
      path {
        fill: #000;
        stroke: #000;
      }
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .customerService {
    padding: 20px;

    &__header {
      color: #ffffff;
      text-align: center;
      font-weight: 500;
      font-size: 30px;
      line-height: 105%;
      letter-spacing: -0.04em;
      padding-bottom: 20px;
    }

    &__message {
      padding: 23px;

      &__header {
        display: block;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: #000000;
        text-align: center;
      }

      label {
        color: #818181;
      }
      button {
        width: 100%;
      }
    }
  }
}
