.payoutCreateRequest {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__form {
    display: grid;
    gap: 24px;
    max-width: 378px;

    &__action {
      padding: 14px 0;
      button {
        border-radius: 4px;
        background: var(--color-primary-1000);
        box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.12) inset,
          0px 2px 5px 0px rgba(60, 66, 87, 0.12),
          0px 1px 1px 0px rgba(0, 0, 0, 0.08);
        border: none;
        width: 100%;
        height: 28px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #fff;
      }
    }
  }

  &__balances {
    display: flex;
    flex-direction: column;
    max-width: 380px;
    gap: 12px;

    &__grid {
      display: grid;
      grid-template-columns: 1fr max-content;
      gap: 12px;

      h6 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: var(--color-neutral-900);
      }

      &--total {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        background-color: var(--color-neutral-200);
        padding: 0 6px;
        border-radius: 4px;
      }

      &--value {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-600);
      }
    }

    &__divide {
      border-bottom: 1px solid var(--color-neutral-200);
    }
  }
}
