@use '../../App.scss' as app;
.createPayment {
  display: grid;
  grid-template-columns: 4fr 1fr 3fr;
  grid-template-areas:
    'amount . email'
    'reference . options'
    'reference . action';
  gap: 20px 0;
  background-color: #fff;
  border-radius: 10px;
  padding: 18px 25px;
  margin-top: 20px;

  &--title {
    grid-area: title;
    display: none;
  }

  &--amount {
    grid-area: amount;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    div {
      font-weight: 700;
      font-size: 35px;
      line-height: 46px;
      sup {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
      }
    }
    input {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      border: none;
      outline: none;
      padding: 8px;
      font-family: 'DM Sans', sans-serif;
      padding-right: 27px;
      background: #fafafa;
      border-radius: 7px;
      color: #000;
      &::placeholder {
        color: #b5bfc9;
      }
    }
    select {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      border: none;
      outline: none;
      padding: 8px;
      font-family: 'DM Sans', sans-serif;
      padding-right: 27px;
      background: #fafafa;
      border-radius: 7px;
      color: #000;
      margin-right: 5px;
      &::placeholder {
        color: #b5bfc9;
      }
    }
  }

  &--reference {
    grid-area: reference;
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      align-items: center;
      gap: 12px;
      span {
        color: #ff0000;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    textarea {
      background: #fafafa;
      border-radius: 7px;
      margin-top: 11px;
      outline: none;
      border: none;
      resize: none;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      height: 75px;
      font-family: 'DM Sans', sans-serif;
      color: black;
      padding: 10px 8px;
      &::placeholder {
        color: #b5bfc9;
      }
    }
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      align-self: flex-end;
    }
  }

  &--email {
    grid-area: email;
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      flex-direction: column;
      gap: 13px;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;

      input {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        border: none;
        outline: none;
        padding: 8px;
        font-family: 'DM Sans', sans-serif;
        padding-right: 27px;
        background: #fafafa;
        border-radius: 7px;
        color: #000;
        &::placeholder {
          color: #b5bfc9;
        }
      }
    }
  }

  &--options {
    grid-area: options;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .MuiFormControlLabel-root {
      display: flex !important;
      flex-direction: row-reverse !important;
      justify-content: space-between;
      margin: 0;

      .MuiTypography-root {
        font-family: 'DM Sans', sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }
    }
    .MuiSwitch-switchBase.Mui-checked {
      color: #f7892e;
      &:hover {
        background-color: hsla(27, 93%, 57%, 0.2);
      }
    }
    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: hsla(27, 93%, 57%, 0.485);
    }
    .advance__options {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #8e8e8e;
      justify-content: space-between;
      select {
        border: none;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: #8e8e8e;
        display: flex;
        align-items: center;
        gap: 8px;
        svg {
          path {
            stroke: #8e8e8e;
          }
        }
      }
    }
  }
  &--action {
    grid-area: action;
    display: flex;
    place-content: flex-end;

    button {
      @include app.dark-btn;
      margin-top: 24px;
      align-self: flex-end;
    }
    button:disabled {
      opacity: 0.3;
      cursor: no-drop;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .createPayment {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'amount'
      'reference'
      'email'
      'options'
      'action';
    gap: 16px;
    background-color: #f5f5f5;
    padding: 22px 19px;
    border-radius: 20px;
    margin-top: 0;

    &--title {
      grid-area: title;
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;

      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
      }
    }

    &--amount {
      grid-area: amount;
      div {
        display: flex;
        flex-direction: row;
        input {
          width: 100%;
        }
      }
      select {
        background-color: #fff;
      }
      input {
        background-color: #fff;
      }
    }
    &--reference {
      grid-area: reference;
      textarea {
        background-color: #fff;
      }
    }
    &--email {
      grid-area: email;
      label {
        input {
          background-color: #fff;
        }
      }
    }
    &--options {
      grid-area: options;

      .advance__options {
        select {
          background-color: #f5f5f5;
        }
      }
    }
    &--action {
      grid-area: action;

      button {
        border: 1px solid #000000;
        border-radius: 10px;
        background: none;
        color: #000000;
        width: 100%;
        padding: 17px;
      }
    }
  }
}
