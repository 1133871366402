.subAccOverview {
  display: grid;

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--color-neutral-900);
    padding-bottom: 27px;
  }

  &__balances {
    table {
      display: grid;
      width: 100%;
      thead {
        border-bottom: 1px solid var(--color-neutral-200);
        padding-bottom: 12px;

        tr {
          display: grid;
          grid-template-columns: 2fr 1fr 1fr;

          th {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-600);
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          display: grid;
          grid-template-columns: 2fr 1fr 1fr;

          td:first-child {
            text-align: left;
          }

          td {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-600);
            text-align: right;
            padding: 11px 0;
          }
        }
      }
      tfoot {
        border-top: 1px dashed var(--Neutral-200, #e9ecef);
        tr {
          display: grid;
          grid-template-columns: 1fr 1fr;

          td {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            padding: 11px 0;
            color: var(--color-neutral-600);
          }

          td:nth-child(2) {
            text-align: right;
          }
        }
      }
    }
  }

  &__latestOrders {
    padding-top: 32px;
    &--amount {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.154px;
      color: var(--color-neutral-900);
    }
    &--currency {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.154px;
      color: var(--color-neutral-500);
      padding-left: 15px;
    }

    table {
      width: 100%;
      thead {
        tr {
          display: grid;
          grid-template-columns: 1fr 1fr 2fr 2fr max-content;
          gap: 30px;
          width: 100%;
          border-bottom: 1px solid var(--color-neutral-200);
          padding-bottom: 12.5px;
          th {
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 11px;
            letter-spacing: 0.34px;
            text-transform: uppercase;
            color: var(--color-neutral-900);
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          display: grid;
          grid-template-columns: 1fr 1fr 2fr 2fr max-content;
          gap: 30px;
          padding: 11px 0;
          border-bottom: 1px solid var(--color-neutral-200);
          td {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 100% */
            letter-spacing: -0.154px;
            color: var(--color-neutral-700);

            button {
              border: none;
              background: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
