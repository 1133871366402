@use '../../App.scss' as app;

.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #ffffff;
  color: #000;
  height: 100vh;

  &__image {
    background-image: url('../../assets/woman.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__type {
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;
    padding: 28px 49px;

    &__header {
      display: grid;
      grid-template-columns: 1fr max-content;

      &--img {
        background-image: url('../../assets/beta-logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 111px;
        height: 37px;
      }
    }

    h3 {
      font-weight: 400;
      font-size: 25px;
      line-height: 33px;
      padding-top: 196px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      padding: 27px 0 53px 0;
    }

    &__loading {
      display: flex;
      padding-top: 113px;
      place-content: center;
    }

    &__actions {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      padding-top: 113px;

      button {
        border: 1px solid #E1E1E1;
        border-radius: 20px;
        font-weight: 400;
        line-height: 23px;
        padding: 17px 30px;
        border-radius: 10px;
        background-color: #fff;
        color: #000;
        cursor: pointer;
      }

      button:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .login {
    display: grid;
    grid-template-columns: 1fr;
    color: #fff;
    background-color: #000;

    &__image {
      display: none;
    }

    &__type {
      padding: 20px;

      &__header {
        display: grid;
        grid-template-columns: 1fr max-content;

        &--img {
          background-image: url('../../assets/beta-logo-white.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 74px;
          height: 36px;
        }
      }

      h3 {
        padding-top: 43px;
        text-align: center;
        font-size: 30px;
      }

      p {
        font-size: 15px;
        text-align: center;
        padding: 5px 0 ;
      }

      &__actions {
        padding-top: 51px;

        button:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
}