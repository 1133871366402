.home {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  &__card {
    display: grid;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;

    &__title {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0.34px;
      color: #30313d;
      padding-bottom: 24px;
    }

    &__menu {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      padding-bottom: 50px;

      &__option {
        cursor: pointer;
        padding-bottom: 12px;
        border-bottom: 2px solid var(--color-neutral-200);

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.154px;
          color: var(--color-neutral-600);
          padding: 12px 20px;
        }
      }

      &--selected {
        border-bottom: 2px solid var(--color-primary-1000);

        span {
          color: var(--color-primary-1000);
        }
      }
    }

    &__item {
      display: grid;

      &__logo {
        display: grid;
        grid-template-columns: max-content 1fr;
        gap: 8px;
        align-items: center;

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.154px;
          color: var(--color-neutral-600);
        }
      }

      &__divider {
        border: 1px solid var(--color-neutral-100);
        margin: 20px 0;
      }

      &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &__amount {
          display: grid;
          gap: 8px;

          &__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-600);
          }

          &__value {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.34px;
            color: var(--color-neutral-900);
          }
        }
      }
    }

    &__option--fiat {
      display: grid;
      grid-template-columns: 1fr max-content 1fr max-content 1fr max-content 1fr;

      &__divide {
        border-right: 1px solid var(--color-neutral-100);
        margin: 0 20px;
      }
    }

    &__option--crypto {
      display: grid;
      grid-template-columns: 1fr max-content 1fr max-content 1fr max-content 1fr max-content 1fr;

      &__divide {
        border-right: 1px solid var(--color-neutral-100);
        margin: 0 20px;
      }
    }
  }
}
