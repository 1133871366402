.payoutCreateBank {
  display: grid;
  gap: 24px;
  max-width: 378px;

  h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.34px;
    color: #687385;
  }

  &--button {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    background: var(--color-primary-1000);
    border-radius: 4px;
    border: none;
    padding: 4px 0;
    box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.12) inset,
      0px 2px 5px 0px rgba(60, 66, 87, 0.12),
      0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  }
}
