.basicLayout {
  display: grid;
  justify-items: center;
  background: #ffffff;
  color: #000;
  padding: 24px 47px;
  min-height: 100vh;

  &__header {
    display: grid;
    grid-template-columns: 1fr;
    width: 1187px;
    padding-bottom: 37px;

    &__container {
      display: flex;
      flex-direction: row;
      gap: 146px;

      &--img {
        background-image: url('../../assets/beta-logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 111px;
        height: 37px;
      }

      &--progress {
        display: flex;
      }
    }
  }

  &__body {
    min-height: calc(100vh - 168px);
    width: 375px;
  }
}

@media only screen and (max-width: 1023px) {
  .basicLayout {
    display: grid;
    justify-items: center;
    background: #000;
    color: #fff;
    padding: 20px;
    min-height: 100vh;
  
    &__header {
      display: grid;
      grid-template-columns: 1fr;
      width: auto;
      padding-bottom: 37px;
  
      &__container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
        'logo logo language'
        '. progress .';
        gap: 0;

        &--img {
          grid-area: logo;
          background-image: url('../../assets/beta-logo-white.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 74px;
          height: 36px;
        }
        &--progress {
          grid-area: progress;
          padding-top: 37px;
        }
        &--language {
          grid-area: language;
        }
      }
    }
  
    &__body {
      width: calc(100vw - 40px);
    }
  }
}