.basicTemplate {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr max-content;
  background: var(--color-neutral-100);
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .basicTemplate {
    display: flex;
    flex-direction: column;
    background-color: #000;
    max-height: 100%;
  }
}
