@use '../../App.scss' as app;
.paymentMenu {
  overflow-y: auto;
  padding: 39px 45px;
  flex: 1;
  color: black;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      color: black;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__table {
    &-container {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 22px;
    }
    &-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #000000;
    }
    &-header {
      display: flex;
      align-items: center;
      gap: 26px;
      input {
        outline: none;
        border: none;
        font-family: 'DM Sans', sans-serif;
        font-weight: 400;
        font-size: 15px;
        margin-left: auto;
        line-height: 20px;
        padding: 3px 15px 3px 32px;
        background: #ffffff;
        border-radius: 10px;
        background-image: url('../../assets/search.svg');
        background-position: 8px 50%;
        background-repeat: no-repeat;
        color: black;
        &::placeholder {
          color: #818181;
        }
      }
    }
    &-dropdown {
      display: flex;
      align-items: center;
      select {
        border: none;
        background-color: #f5f5f5;
      }
    }
  }
}

.loading-button {
  display: flex;
  place-content: flex-end;
}

@media only screen and (max-width: 1023px) {
  .paymentMenu {
    padding: 0 20px 20px 20px;

    &__header {
      display: none;
    }

    &__table {
      &-container {
        background: #f5f5f5;
        border-radius: 20px;
        padding: 22px 19px;
      }
      &-title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 26px;
        color: #000000;
        letter-spacing: -0.04em;
      }
      &-header {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;

        input {
          width: 100%;
        }
      }
      &-dropdown {
        select {
          border: none;
          background-color: #fff;
          width: 100%;
          height: 37px;
          border-radius: 6px;
        }
      }
    }
  }
}
