.success {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  background-color: #fff;
  height: 100vh;
  padding: 30px 44px;

  &__content {
    width: 1187px;

    &__header {
      &--img {
        background-image: url('../../assets/beta-logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 111px;
        height: 37px;
      }
    }

    &__body {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      color: #000;
      width: 375px;
      margin: 0 auto;

      &__icon {
        margin: 112px 0 56px 0;
        background-image: url('../../assets/success-black.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 97px;
        height: 97px;
      }

      h1 {
        font-size: 25px;
        font-weight: 400;
        line-height: 33px;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        padding-top: 46px;
      }

      &__action {
        padding-top: 20px;
      }

      button {
        border: 1px solid #e1e1e1;
        border-radius: 20px;
        font-weight: 400;
        line-height: 23px;
        padding: 17px 30px;
        border-radius: 10px;
        background-color: #fff;
        color: #000;
        cursor: pointer;
      }

      button:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .success {
    padding: 20px;
    background-color: #000;

    &__content {
      width: 100%;

      &__header {
        &--img {
          background-image: url('../../assets/beta-logo-white.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 74px;
          height: 36px;
        }
      }
      &__body {
        background-color: #000;
        color: #fff;
        width: auto;
        margin: 0;

        &__icon {
          background-image: url('../../assets/success-white.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 66px;
          height: 66px;
          margin: 53px 0 10px 0;
        }

        p {
          text-align: justify;
        }
      }
    }
  }
}
