.subAccDeposit {
  display: grid;
  gap: 24px;
  max-width: 378px;

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--color-neutral-900);
  }

  &__form {
    display: grid;
    gap: 20px;
  }

  &__action {
    padding: 16px 0;
    button {
      background-color: var(--color-primary-1000);
      color: #fff;
      border-radius: 4px;
      border: none;
      padding: 7px 0;
      width: 100%;
      cursor: pointer;
    }
  }

  &__result {
    display: flex;
    gap: 16px;
    flex-direction: column;

    h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      color: var(--color-neutral-900);
      padding-bottom: 4px;
    }

    &--item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-700);
        word-wrap: break-word; // Adicionado aqui
      }
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-600);
        word-wrap: break-word;
        width: 378px;
      }
    }
  }
}
