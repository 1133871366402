.orders {
  display: grid;

  &__divideHorizontal {
    width: 100%;
    border-bottom: 1px solid var(--color-neutral-200);
  }

  &__card {
    padding: 24px 40px 40px 40px;
    background-color: #fff;
    &__title {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      align-items: center;
      gap: 8px;
      padding-bottom: 24px;

      h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.34px;
        color: var(--color-neutral-900);
      }
    }

    &__menu {
      display: grid;
      grid-template-columns: repeat(4, max-content) 1fr;
      padding-bottom: 20px;

      &__option {
        cursor: pointer;
        padding-bottom: 12px;
        border-bottom: 2px solid var(--color-neutral-200);

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.154px;
          color: var(--color-neutral-600);
          padding: 12px 20px;
        }
      }

      &--selected {
        border-bottom: 2px solid var(--color-primary-1000);

        span {
          color: var(--color-primary-1000);
        }
      }

      &__filter {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
      }
    }

    &__table {
      &--amount {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-900);
      }
      &--currency {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-500);
        padding-left: 15px;
      }

      table {
        width: 100%;
        thead {
          tr {
            display: grid;
            grid-template-columns: repeat(5, 1fr) max-content;
            gap: 30px;
            width: 100%;
            border-bottom: 1px solid var(--color-neutral-200);
            padding-bottom: 12.5px;
            th {
              font-size: 11px;
              font-style: normal;
              font-weight: 500;
              line-height: 11px;
              letter-spacing: 0.34px;
              text-transform: uppercase;
              color: var(--color-neutral-900);
              text-align: left;
            }
          }
        }
        tbody {
          tr {
            display: grid;
            grid-template-columns: repeat(5, 1fr) max-content;
            gap: 30px;
            padding: 16px 0;
            border-bottom: 1px solid var(--color-neutral-200);
            td {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 100% */
              letter-spacing: -0.154px;
              color: var(--color-neutral-700);

              button {
                border: none;
                background: none;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    &__action {
      display: grid;
      grid-template-columns: 1fr max-content;
      padding-top: 24px;
      align-items: center;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-600);
      }

      &--button {
        display: flex;
        flex-direction: row-reverse;
        gap: 12px;
        button {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.154px;
          border-radius: 4px;
          border: 1px solid var(--color-neutral-200);
          background: #fff;
          padding: 0px 8px;
          height: 28px;
          color: var(--color-neutral-800);

          /* button */
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
            0px 2px 5px 0px rgba(60, 66, 87, 0.08);
        }

        button:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
