.faq {
  padding: 21px 25px;
  background-color: var(--color-neutral-100);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  color: #000000;
  cursor: pointer;

  &__question {
    display: grid;
    grid-template-columns: 1fr max-content;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    gap: 10px;
    svg {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      width: 20px;
      height: 20px;
      path {
        stroke: #000;
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  &.active {
    .faq__question {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .faq {
    padding: 23px;
    &__question {
      font-weight: 500;
      font-size: 18px;
      letter-spacing: -0.04em;
      color: #000000;
    }
  }
}
