.payout {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 24px 40px;
  background-color: #fff;
  min-height: calc(100vh - 52px);

  h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.34px;
    color: var(--color-neutral-900);
  }

  &__recipient {
    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.34px;
      color: var(--color-neutral-600);
      padding-bottom: 24px;
    }

    &__grid {
      display: flex;
      gap: 80px;

      &__input {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 378px;

        &--search {
          border-radius: 4px;
          background-color: var(--color-primary-1000);
          border: none;
          height: 28px;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          color: #fff;
        }

        &--register {
          color: var(--color-primary-1000);
          background: none;
          border: none;
          text-align: left;
          align-items: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.154px;

          img {
            padding-left: 8px;
          }
        }
      }

      &__info {
        width: 360px;
        &__card {
          display: flex;
          flex-direction: column;
          padding: 12px;
          background-color: var(--color-neutral-100);
          border-radius: 6px;

          &--title {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            background-color: var(--color-neutral-200);
            border-radius: 4px;
            padding: 0 6px;
            color: var(--color-neutral-600);
            align-self: flex-start;
          }

          &--info {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.154px;
            color: var(--color-primary-1000);
            padding-top: 16px;
          }

          &--text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-700);
            padding-top: 8px;
          }
        }
      }
    }
  }

  &__currency {
    max-width: 378px;
    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.34px;
      color: var(--color-neutral-600);
      padding-bottom: 24px;
    }

    &__actions {
      &--register {
        color: var(--color-primary-1000);
        background: none;
        border: none;
        text-align: left;
        align-items: center;
        margin-top: 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.154px;

        img {
          padding-left: 8px;
        }
      }
      &__grid {
        display: grid;
        grid-template-columns: repeat(2, 140px);
        gap: 11px;
        padding-top: 24px;

        button {
          border-radius: 4px;
          border: 1px solid var(--color-primary-1000);
          height: 28px;
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
            0px 2px 5px 0px rgba(60, 66, 87, 0.08);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          color: #fff;
        }

        &--back {
          background-color: var(--color-neutral-900);
        }
        &--continue {
          background-color: var(--color-primary-1000);
        }
      }
    }
  }
}
