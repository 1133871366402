@use '../../App.scss' as app;

.registerAccount {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #ffffff;
  color: #000;
  height: 100vh;

  &__image {
    background-image: url('../../assets/woman.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__type {
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;
    padding: 28px 49px;

    &__header {
      display: grid;
      grid-template-columns: 1fr max-content;

      &--img {
        background-image: url('../../assets/beta-logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 111px;
        height: 37px;
      }
    }

    &__progress {
      padding-top: 152px;
    }

    h3 {
      font-weight: 400;
      font-size: 25px;
      line-height: 33px;
      padding-top: 32px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      padding: 27px 0 53px 0;
    }

    &__cards {
      display: grid;
      flex-direction: row;
      gap: 23px;

      &--grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }

      .cards__style {
        border: 1px solid #e1e1e1;
        border-radius: 20px;
        padding: 21px 19px;

        &__title {
          display: flex;
          gap: 8px;
          text-transform: capitalize;

          input {
            cursor: pointer;
            accent-color: #000;
            width: 20px;
          }

          input[type='radio'] {
            border: 0px;
            width: 20px;
            height: 20px;
          }

          label {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }
    }

    &__action {
      display: flex;
      justify-content: flex-end;
      padding-top: 72px;

      button {
        font-weight: 400;
        line-height: 23px;
        padding: 17px 30px;
        border-radius: 10px;
        background-color: #000;
        color: #ffffff;
        cursor: pointer;
      }

      button:disabled {
        opacity: 0.7;
        cursor: no-drop;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .registerAccount {
    display: grid;
    grid-template-columns: 1fr;
    color: #fff;
    background-color: #000;

    &__type {
      padding: 20px;
      display: grid;

      &__header {
        display: grid;
        grid-template-columns: 1fr max-content;

        &--img {
          background-image: url('../../assets/beta-logo-white.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 74px;
          height: 36px;
        }
      }

      &__progress {
        display: flex;
        place-content: center;
        padding-top: 40px;
      }

      h3 {
        font-size: 30px;
        text-align: center;
      }

      p {
        font-size: 15px;
        text-align: center;
        padding: 5px 0 40px 0;
      }

      &__cards {
        color: #000;

        &--grid {
          display: grid;
          grid-template-columns: 1fr;
        }

        .cards__style {
          padding: 20px 25px;
          background-color: #fff;
          border-radius: 10px;

          &__title {
            padding-bottom: 10px;
          }

          p {
            text-align: left;
            padding: 0;
          }
        }
      }
      &__action {
        position: absolute;
        bottom: 20px;
        right: 20px;
        padding-top: 0px;
        left: 20px;

        button {
          width: 100%;
          border: 1px solid #ffffff;
        }
      }
    }

    &__image {
      display: none;
    }
  }
}
