.nav {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: black;
  width: 200px;
  padding: 20px;
  background-color: var(--color-neutral-100);
  box-shadow: 0px 0px 12px 0px rgba(2, 0, 39, 0.08);

  &__logo {
    padding: 1px 0 17px 12px;

    img {
      width: 50px;
    }
  }

  &__option {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 8px;
    cursor: pointer;
    text-decoration: none;
    color: var(--color-neutral-900);
  }

  &__menu {
    &__button {
      display: grid;
      grid-template-columns: 1fr max-content;
      text-align: left;
      align-items: center;
      padding: 5px 8px;
      color: #fff;
      flex-direction: column;
      flex-shrink: 0;
      border-radius: 4px;
      background: var(--color-primary-1000);
      border: none;
      cursor: pointer;
      width: 100%;
    }
    &__option {
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;
      color: var(--color-neutral-900);
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &__submenu {
    display: grid;
    margin-top: 16px;
    &__option {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: var(--color-neutral-600);
      border-left: 2px solid var(--color-neutral-200);
      padding: 8px;
      text-decoration: none;
    }
    &__option--active {
      border-left: 2px solid var(--color-primary-1000);
      color: var(--color-primary-1000);
    }
    &__option--sub {
      font-size: 12px;
      padding: 8px 0 8px 20px;
    }

    &__dialog {
      &__content {
        display: flex;
        flex-direction: column;
        width: 228px;

        a {
          display: flex;
          align-items: center;
          gap: 12px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.154px;
          text-decoration: none;
          color: var(--color-neutral-900);
          padding: 16px 0 16px 20px;

          &:hover {
            background-color: var(--color-neutral-100);
          }
        }
      }
    }
  }
}
