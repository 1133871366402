.navHeader {
  display: grid;
  grid-template-columns: 1fr repeat(3, max-content);
  gap: 16px;
  background-color: var(--color-neutral-100);
  padding: 14px 40px;

  a {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    text-decoration: none;
    gap: 8px;
    cursor: pointer;

    span {
      font-size: 12px;
      font-weight: 400;
      color: var(--color-neutral-700);
    }

    svg {
      width: 12px;
    }
  }
}
