.payoutStep {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 14px;
  max-width: 550px;

  &__item {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 12px 8px;
    align-items: center;
    grid-template-areas:
      'number line'
      'text text';

    &__number {
      grid-area: number;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 13px;
      border-radius: 24px;
      width: 24px;
      height: 24px;
      border: 2px solid var(--color-neutral-500);
      color: var(--color-neutral-400);

      &--active {
        border: 2px solid var(--color-primary-1000);
        color: var(--color-primary-1000);
      }

      &--finished {
        background-color: var(--color-primary-1000);
        border: 2px solid var(--color-primary-1000);
        color: #fff;
      }
    }

    &__line {
      grid-area: line;
      width: 100%;
      height: 2px;
      background-color: #d9d9d9;

      &--finished {
        background-color: var(--color-primary-1000);
      }
    }

    &__text {
      grid-area: text;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.154px;
      color: var(--color-neutral-900);
    }
  }
}
