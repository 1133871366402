:root {
  --color-neutral-100: #f8f9fa;
  --color-neutral-200: #e9ecef;
  --color-neutral-300: #dee2e6;
  --color-neutral-400: #ced4da;
  --color-neutral-500: #adb5bd;
  --color-neutral-600: #6c757d;
  --color-neutral-700: #495057;
  --color-neutral-800: #343a40;
  --color-neutral-900: #212529;
  --color-neutral-1000: #000000;

  --color-primary-100: #e3e1fe;
  --color-primary-200: #d9d6ff;
  --color-primary-300: #ccc8fe;
  --color-primary-400: #c3beff;
  --color-primary-500: #bcb6fe;
  --color-primary-600: #aaa5ff;
  --color-primary-700: #9d98ff;
  --color-primary-800: #8c86ff;
  --color-primary-900: #766fff;
  --color-primary-1000: #625afa;

  --color-error-light: #ffe7f2;
  --color-error-dark: #b3093c;

  --color-positive-light: #d7f7c2;
  --color-positive-dark: #006908;

  --color-info-light: #cff5f6;
  --color-info-dark: #0055bc;

  --color-attention-light: #fcedb9;
  --color-attention-dark: #a82c00;
}
