.uploadDocuments {
  display: grid;
  color: #000;

  &__upload {
    display: flex;
    align-items: center;
    gap: 20;
    gap: 5px;

    label {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      color: #0038ff;
      cursor: pointer;
    }

    &--icon {
      background-image: url('../../assets/cloud-upload-blue.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
    }

    input {
      display: none;
    }
  }

  ul {
    padding: 20px 0 20px 20px;
    li {
      div {
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
        gap: 5px;

        span {
          color: black;
        }

        button {
          border: none;
          background-color: #fff;
          cursor: pointer;
        }

        button:disabled {
          cursor: no-drop;
          opacity: 0.3;
        }
      }
    }
  }

  &__action {
    display: grid;
    max-width: 362px;

    button {
      border: none;
      border-radius: 6px;
      padding: 10px;
      width: 100%;
      cursor: pointer;
      background-color: var(--color-primary-900);
      color: #fff;
    }

    button:disabled {
      cursor: no-drop;
    }
  }
}
