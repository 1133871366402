.input {
  display: grid;
  grid-template-columns: 1fr;

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.154px;
    color: var(--color-neutral-900);

    span {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: #ff2d55;
    }
  }

  &__message {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: var(--color-neutral-700);
    padding-top: 4px;
  }

  input {
    border: none;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--color-neutral-400);
    background: none;
    height: 30px;
    margin-top: 3px;
  }

  ::placeholder {
    color: #b5bfc9;
  }

  input:disabled {
    opacity: 0.5;
  }

  &__messageError {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: #df1b41;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .input {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px !important;

    label {
      font-size: 15px;
      font-weight: 400;
    }
  }
}
