.form {
  h1 {
    font-size: 25px;
    font-weight: 400;
    line-height: 33px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    padding: 26px 0 50px 0;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 34px;
  }

  &__terms {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 10px;
    align-items: flex-start;

    input {
      width: 16px;
      height: 16px;
      border: 1px solid #000000;
      border-radius: 4px;
      appearance: none;
      background-color: white;
      outline: none;
      cursor: pointer;
    }

    input:checked {
      appearance: textfield;
    }

    span {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;

      a:link {
        text-decoration: none;
      }
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(2, 165px);
    gap: 86px;
    padding-top: 18px;

    button {
      line-height: 23px;
      font-weight: 400;
      cursor: pointer;
    }

    &__blank {
      border: none;
      background-color: #fff;
    }

    &__black {
      background-color: #000;
      color: #fff;
      padding: 17px;
      border-radius: 10px;
    }

    button:disabled {
      cursor: no-drop;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .form {
    background-color: #000;
    
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      font-size: 15px;
      padding: 10px 0 31px 0;
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr;
      gap: 19px;
    }

    &__actions {
      display: grid;
      gap: 0px;
      justify-content: space-between;

      &__blank {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        border: none;
        color: #fff;
        background-color: #000;
      }
  
      &__black {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        background-color: #000;
        color: #fff;
        padding: 17 19px;
        border-radius: 10px;
        border: 1px solid #FFFFFF;
        word-wrap: none;
      }
    }
  }
}
