.exchange {
  display: grid;
  grid-template-columns: 419px 1fr;
  gap: 80px;
  padding: 40px;
  background-color: #fff;
  height: 100%;

  &__card {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h1 {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0.34px;
      color: var(--color-neutral--900);
    }

    &__asset {
      display: grid;
      h2 {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: var(--color-neutral--700);
        background-color: var(--color-neutral-200);
        border-radius: 4px;
        padding: 2px 6px;
        width: max-content;
      }

      &--style {
        display: grid;
        gap: 20px;
        border-radius: 4px;
        background-color: var(--color-neutral-100);
        padding: 16px 24px;
      }

      &__content {
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;

        input {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 100% */
          letter-spacing: -0.154px;
          background-color: var(--color-neutral-100);
          border: none;
        }

        select {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.154px;
          color: var(--color-neutral-900);

          width: 120px;
          height: 30px;
          box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.12),
            0px 1px 1px 0px rgba(0, 0, 0, 0.08);
          border: 1px solid var(--color-neutral-100);
          border-radius: 6px;
        }
      }
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        color: var(--color-neutral-700);
        padding: 8px 0 0 20px;
      }
    }

    &__switch {
      display: flex;
      gap: 4px;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.154px;
        color: var(--color-neutral--700);
        padding-left: 12px;
      }
    }

    &__action {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: #fff;
      background-color: var(--color-primary-1000);
      border-radius: 4px;
      box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.12) inset,
        0px 2px 5px 0px rgba(60, 66, 87, 0.12),
        0px 1px 1px 0px rgba(0, 0, 0, 0.08);
      border: none;
      padding: 6px;
    }

    &__details {
      h2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        color: #687385;
      }

      &__grid {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 20px;

        &--id {
          font-family: Menlo;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 11px;
          color: var(--color-neutral-600);
          border-radius: 4px;
          border: 1px solid var(--color-neutral-200);
          background: var(--color-neutral-100);
          padding: 3px 6px;
          width: max-content;
        }

        &__item {
          display: flex;
          flex-direction: column;
          gap: 8px;
          span {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            color: var(--color-neutral-600);
          }
          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-600);
          }

          &__action {
            padding-top: 30px;

            &--inactive {
              background-color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: -0.154px;
              padding: 4px 8px;
              border-radius: 4px;
              border: 1px solid var(--color-neutral-200);
            }
          }
        }
      }
    }
  }
}
