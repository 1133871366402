.poolBalance {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 24px 40px;
  background-color: #fff;
  min-height: calc(100vh - 52px);

  h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.34px;
    color: var(--color-neutral-900);
  }

  &__balances {
    table {
      display: grid;
      width: 680px;
      thead {
        border-bottom: 1px solid var(--color-neutral-200);
        padding-bottom: 12px;

        tr {
          display: grid;
          grid-template-columns: 2fr 1fr;

          th {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-600);
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          display: grid;
          grid-template-columns: 2fr 1fr;

          td:first-child {
            text-align: left;
          }

          td {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-600);
            text-align: right;
            padding: 11px 0;
          }
        }
      }
      tfoot {
        border-top: 1px dashed var(--Neutral-200, #e9ecef);
        tr {
          display: grid;
          grid-template-columns: 1fr 1fr;

          td {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            padding: 11px 0;
            color: var(--color-neutral-600);
          }

          td:nth-child(2) {
            text-align: right;
          }
        }
      }
    }
  }
}
