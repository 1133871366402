.payoutBankDetails {
  display: grid;
  max-width: 408px;

  gap: 24px;
  &__card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 4px;
    background: var(--color-neutral-100);
    padding: 16px;
    align-self: flex-start;

    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      color: var(--color-neutral-700);
    }

    &__grid {
      display: flex;
      flex-direction: column;
      gap: 8px;

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.154px;
        color: #3c4257;
      }

      span {
        font-family: Menlo;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 11px;
        color: var(--color-neutral-600);
        border-radius: 4px;
        border: 1px solid var(--color-neutral-200);
        background: #fff;
        padding: 4px 6px;
        align-self: flex-start;
      }
    }
  }
  &__action {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    button {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      border: none;
      border-radius: 4px;
      padding: 4px 62px;
      cursor: pointer;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
        0px 2px 5px 0px rgba(60, 66, 87, 0.08);
    }

    &--override {
      background: var(--color-attention-light);
      color: var(--color-attention-dark);
    }
    &--continue {
      background: var(--color-neutral-900);
      color: #fff;
    }
  }
}
