.settings {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0 20px 20px 20px;
  overflow-y: auto;
  background-color: #fff;

  &__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 26px;
    color: var(--color-neutral-900);
    padding: 39px 0 20px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 20px;
    gap: 31px;

    &__line {
      border-bottom: 1px solid var(--color-neutral-200);
    }

    h3 {
      font-weight: 400;
      font-size: 20px;
      line-height: 105%;
      letter-spacing: -0.04em;
      color: var(--color-neutral-600);
    }

    &__fee {
      display: flex;
      flex-direction: column;
      gap: 18px;

      h3 {
        color: var(--color-neutral-600);
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.34px;
        padding-bottom: 18px;
      }
    }

    &__info {
      display: grid;
      gap: 10px;
      align-content: baseline;

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000;
      }
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: var(--color-neutral-900);
      }
      img {
        width: 100px;
      }
      &--brand {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #b5bfc9;
        text-align: left;
      }
    }

    &__option {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 362px;

      &--country {
        width: 100% !important;
      }

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000;
      }

      select {
        background: #fff;
        border: 1px solid var(--color-neutral-200);
        border-radius: 4px;
        box-shadow: 0px 2px 5px 0px rgba(60, 66, 87, 0.12),
          0px 1px 1px 0px rgba(0, 0, 0, 0.08);
      }
      &--info {
        display: flex;
        gap: 10px;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: var(--color-neutral-900);
        }
        button {
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          background: none;
          border: none;
          color: var(--color-primary-900);
          cursor: pointer;
        }
      }
      &--change {
        display: flex;
        gap: 10px;

        input {
          background: #f5f5f5;
          border-radius: 7px;
          border: none;
          padding: 0 5px;
        }

        button {
          border: 1px solid #000;
          border-radius: 3px;
          padding: 0 5px;
          font-style: normal;
          font-weight: 400;
          cursor: pointer;
        }
      }
      &--radio {
        display: flex;
        gap: 8px;
        align-items: center;

        input {
          cursor: pointer;
          accent-color: #000;
          width: 20px;
        }

        input[type='radio'] {
          border: 0px;
          width: 18px;
          height: 2em;
        }

        label {
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: #8e8e8e;
          }
        }
      }

      &--input {
        input {
          background: #fff;
          border-radius: 7px;
          border: none;
          padding: 0 5px;
          width: 100%;
        }
      }
      &--action {
        padding-top: 10px;
        max-width: 362px;
        button {
          background-color: var(--color-primary-900);
          border-radius: 10px;
          padding: 10px;
          width: 100%;
          cursor: pointer;
          color: #fff;
          border: none;
        }
        button:disabled {
          opacity: 0.3;
          cursor: no-drop;
        }
      }
      &--texts {
        display: flex;
        flex-direction: column;
        gap: 14px;

        &-title {
          color: #000;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
        }
        &-value {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #b5bfc9;
        }
      }
      &--asset {
        display: flex;
        flex-direction: column;
        gap: 14px;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .settings {
    &__title {
      display: none;
    }

    &__container {
      display: flex;
      flex-direction: column;
      background: #f5f5f5;

      h3 {
        text-align: center;
      }

      &__fee {
        h3 {
          text-align: center;
        }
      }

      &__option {
        select {
          background: #fff;
        }
        &--change {
          input {
            background: #fff;
          }
        }
        &--stablecoin {
          select {
            background: #fff;
          }
        }
        &--input {
          input {
            background: #fff;
          }
        }
      }
    }
  }
}
