@use '../../App.scss' as app;
.transactionDetails {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 39px 45px;
  flex: 1;
  color: black;
  min-height: 80vh;

  &__header {
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 16px;
    section {
      flex: 1;
      width: 100%;
    }
  }

  &__amount {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    span {
      font-weight: 700;
      font-size: 35px;
      line-height: 46px;
      sup {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
      }
    }
    &-box {
      background: #ffffff;
      border-radius: 10px;
      padding: 18px 38px 18px 25px;

      &-title {
        display: none;
      }
    }
  }
  &__personal-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 15px;
  }
  &__detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #b5bfc9;
      margin-right: 15px;
    }
    div {
      border-radius: 4px;
      height: 38px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #121212;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__timeline {
    &-container {
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 24px 26px;
      padding-bottom: 48px;
    }
    &-text {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 12px;
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #b5bfc9;
      }
    }
    &-box {
      display: flex;
      gap: 28px;
      margin-top: 60px;
      padding: 0 8px;
    }
    &-details {
      display: flex;
      flex-direction: column;
      gap: 31px;
    }
    &-grid {
      display: grid;
      grid-template-columns: 40px 1fr;
      gap: 20px;
    }
    &-detail {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000;
      display: flex;
      flex-direction: column;
      gap: 5px;
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #b5bfc9;
      }
    }
  }
}

.loading {
  display: grid;
  place-content: center;
  height: 100vh;
}

@media only screen and (max-width: 1023px) {
  .transactionDetails {
    padding: 0 20px 20px 20px;

    &__header {
      display: none;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 0;
    }

    &__amount-box {
      background: #f5f5f5;
      border-radius: 20px;

      &-title {
        display: block;
        font-weight: 500;
        font-size: 30px;
        line-height: 105%;
        text-align: center;
        padding-bottom: 24px;
        letter-spacing: -0.04em;
      }
    }
    &__timeline {
      &-container {
        color: #fff;
        background: #000;
      }
      &-box {
        margin-top: 42px;
        padding: 0;
      }
      &-text {
        display: grid;
        place-content: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 105%;
      }
      &-grid {
        img {
          width: 40px;
          filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(100deg)
            brightness(100%) contrast(100%);
        }
      }
      &-detail {
        color: #fff;
      }
    }
    &__action {
      padding-top: 0;
      button {
        color: #fff;
        border: 1px solid #FFFFFF;
        border-radius: 10px;
        width: 100%;
        font-weight: 400;
        line-height: 23px;
        padding: 17px;
      }
    }
  }
}
