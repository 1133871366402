.registerSubAcc {
  display: grid;
  grid-template-columns: 378px 336px;
  gap: 40px;
  padding: 24px 40px;
  background-color: #fff;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.34px;
      color: var(--color-neutral-900);
    }

    &--button {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: #fff;
      background: var(--color-primary-1000);
      border-radius: 4px;
      border: none;
      padding: 4px 0;
      box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.12) inset,
        0px 2px 5px 0px rgba(60, 66, 87, 0.12),
        0px 1px 1px 0px rgba(0, 0, 0, 0.08);
    }
  }

  &__info {
    padding-top: 55px;
    &__card {
      display: flex;
      flex-direction: column;
      padding: 12px;
      background-color: var(--color-neutral-100);
      border-radius: 6px;

      &--title {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        background-color: var(--color-neutral-200);
        border-radius: 4px;
        padding: 0 6px;
        color: var(--color-neutral-600);
        align-self: flex-start;
      }

      &--info {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.154px;
        color: var(--color-primary-1000);
        padding-top: 16px;
      }

      &--text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-700);
        padding-top: 8px;
      }
    }
  }
}
