.subAccountDetails {
  display: grid;

  &__divideHorizontal {
    width: 100%;
    border-bottom: 1px solid var(--color-neutral-200);
  }

  &__card {
    display: grid;
    grid-template-columns: 3fr 5fr;
    gap: 40px;
    padding: 24px 40px 40px 40px;
    background-color: #fff;

    &--left {
      border-right: 1px solid var(--color-neutral-200);
      padding-right: 40px;
    }

    &__title {
      display: grid;
      align-items: center;
      gap: 4px;
      padding-bottom: 36px;

      h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.34px;
        color: var(--color-neutral-900);
      }

      &--email {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.154px;
        color: var(--color-neutral-700);
        padding-bottom: 36px;
      }

      &__resume {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;
        padding-bottom: 36px;

        &__item {
          display: flex;
          flex-direction: column;
          gap: 10px;

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-600);
          }

          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: -0.154px;
            color: var(--color-neutral-900);
          }
        }
      }

      &__details {
        h2 {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          padding-bottom: 8px;
        }

        &__grid {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding-top: 20px;

          &--id {
            font-family: Menlo;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 11px;
            color: var(--color-neutral-600);
            border-radius: 4px;
            border: 1px solid var(--color-neutral-200);
            background: var(--color-neutral-100);
            padding: 3px 6px;
            width: max-content;
          }

          &__item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            span {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 12px;
              color: var(--color-neutral-600);
            }
            p {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: -0.154px;
              color: var(--color-neutral-600);
            }

            &__action {
              padding-top: 30px;

              &--inactive {
                background-color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: -0.154px;
                padding: 4px 8px;
                border-radius: 4px;
                border: 1px solid var(--color-neutral-200);
              }
            }
          }
        }
      }
    }

    &__menu {
      display: grid;
      grid-template-columns: repeat(3, max-content) 50px max-content;
      padding-bottom: 20px;

      &__option {
        cursor: pointer;
        padding-bottom: 12px;
        border-bottom: 2px solid var(--color-neutral-200);

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.154px;
          color: var(--color-neutral-600);
          padding: 12px 20px;
        }
      }

      &--selected {
        border-bottom: 2px solid var(--color-primary-1000);

        span {
          color: var(--color-primary-1000);
        }
      }
    }
  }
}
