.menuBar {
  display: grid;
  grid-template-columns: repeat(4, max-content) 1fr;

  &__option {
    cursor: pointer;
    padding-bottom: 12px;
    border-bottom: 2px solid var(--color-neutral-200);

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.154px;
      color: var(--color-neutral-600);
      padding: 12px 20px;
    }
  }

  &--selected {
    border-bottom: 2px solid var(--color-primary-1000);

    span {
      color: var(--color-primary-1000);
    }
  }
}
